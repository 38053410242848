import { createApp } from 'vue'
import App from './App.vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { createWebHistory, createRouter } from "vue-router";

const routes = [
	{ path: '/', component: () => import("./Index.vue"), },
	{ path: '/map', component: () => import("./Map.vue"), },
]

const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

const app = createApp(App);

app.use(router)
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBtCH7-l76G9R4wAzlQxSod0w6FLLFqcjY',
    },
})

app.mount('#app');